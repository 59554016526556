
<template>
  <div :class="containerClasses">
    <label :class="'btn-switch'+(props.disabled?' disabled':'')" :title="props.title" :id="identifier+'-label'">
      <input type="checkbox" class="switch-internal-checkbox" v-model="valueRef" @change="handleChange" @keyup="handleKeyup"
             :id="identifier+'_input'" :readonly="props.readonly" :disabled="props.disabled" :aria-labelledby="identifier+'-label'"/>
      <span v-if="valueRef && !noIcons" class="switch-span switch-active">
        <nscale-icon size="medium" :iconKey="props.oniconkey" hover="true"/>
      </span>
      <span v-else-if="!valueRef && !noIcons" class="switch-span">
        <nscale-icon size="medium" :iconKey="props.officonkey" hover="true"/>
      </span>
      <div v-else :class="classes" >
        <div class="switch-hover-focus"/>
        <div class="switch-no-icon-handle">
          <nscale-icon size="medium" iconkey="check"/>
        </div>
      </div>
    </label>
  </div>
</template>

<script setup lang="ts">
import {computed, onMounted, Ref, ref, watch, defineEmits} from 'vue'
import {toBoolean} from "Utils/parser/boolean";

const props = defineProps<{
  label: string
  title: string
  value: string
  event: string
  readonly: boolean
  disabled: boolean
  identifier: string
  oniconkey: string
  officonkey: string
  class: string
}>()
const identifier = props.identifier ? props.identifier : 'nscale-switch-'+Math.random()
const valueRef: Ref<boolean> = ref(false)

const emit = defineEmits(['change'])
onMounted(() => {
  valueRef.value = toBoolean(props.value)
})

const noIcons = computed((): boolean => {
  return props.oniconkey == null || props.oniconkey.length == 0 ||
      props.officonkey == null || props.officonkey.length == 0
})

const containerClasses = computed((): string[] => {
  let classes = ['switch-container']
  if(props.class && props.class.length > 0) {
    const customClasses = props.class.split(' ')
    for (const customClass of customClasses) {
      classes.push(customClass)
    }
  }
  if(noIcons) classes.push('has-custom-icons')
  return classes
})

const classes = computed((): string[] => {
  let classes = ['switch-no-icon-container']
  if(toBoolean(valueRef.value)) classes.push('switch-active')
  return classes
})

watch(() => props.value, () => {
  valueRef.value = toBoolean(props.value)
})

function handleChange() {
  if(props.event) {
    if(props.event.indexOf('_CHECKBOXVALUE_') >= 0) {
      const url = props.event.replace('_CHECKBOXVALUE_', ''+valueRef.value);

      /*
      * TODO !!!!!!!!!!!!!!!
      * Hier wird erstmal weiterhin die Methode von Tapestry verwendet, weil hier offenbar noch ein wichtiger Aspekt passiert.
      * Im Modul 'web-sdk/tapestry-etal/src/main/generated/compiled-processed-coffeescript/org/apache/tapestry5/t5-core-dom-jquery.js'
      * Zeile 346 wird, wenn der Status 200 zurückgemeldet wurde, noch etwas Magie betrieben damit Tapestry Javascripte ausführt, welche
      * über den ajaxResponseRenderer in die Warteschlange mit aufgenommen wurden. Um 't5/core/ajax' ablösen zu können muss der Mechanismus hier
      * nachempfunden werden. In diesem Beispiel ist das konkrete Problem, das die Methode 'toggleInitialMultiLineCollapsedState' im listview.js Modul
      * nicht aufgerufen wird, welche in der Listview.java bei onChangeInitialMutliLineCollapsedState-Methode in der Listview.java über den
      * ajaxResponseRenderer dem Javascript-Stack zur Ausführung hinzugefügt wird.
      * ===> Problem für z.B. Zukunft's-Romeo
      * */
      require('t5/core/ajax')(url, {})
    }
  }
  emit('change', valueRef.value)
}

function handleKeyup(e: KeyboardEvent) {
  if(e.key === 'Enter') {
    valueRef.value = !valueRef.value
    handleChange()
  }
}

</script>