import { ResourceLanguage } from "i18next"

export default {
  translation: {
    clock: "o' clock",
    hour: "hour",
    minute: "minute",
    second: "second",
    range: {
      start: "Start date",
      end: "End date",
    },
    yesterday: "Yesterday",
    today: "Today",
    tomorrow: "Tomorrow",
    lastWeek: "Last week",
    thisWeek: "This week",
    nextWeek: "Next week",
    lastQuarter: "Last quarter",
    thisQuarter: "This quarter",
    nextQuarter: "Next quarter",
    lastYear: "Last year",
    thisYear: "This year",
    nextYear: "Next year",
    invalidDate: "invalid date",
  },
} as ResourceLanguage
