import { defineCustomElement, DefineComponent, ComponentOptionsBase } from "vue"

export const generateUniqueId = () => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  )
}

export const registerCustomElement = (
  name: string,
  component: ComponentOptionsBase<any, any, any, any, any, any, any, any>
) => {
  const registeredCustomElement = customElements.get(name)
  if (!registeredCustomElement) {
    const newCustomElement = defineCustomElement(component)
    customElements.define(name, newCustomElement)
  }
}
