import moment from "moment"
import "moment/dist/locale/de"

enum DateMomentFormat {
  DDMMYYYY = "DD.MM.YYYY",
  DDMMYY = "DD.MM.YY",
  ISO = "YYYY-MM-DD HH:mm:ss",
  DayDDMonthYYYY = "dddd, DD. MMMM YYYY",
  DDMonth = "DD. MMMM",
  MonthYYYY = "MMMM YYYY",
  YYYY = "YYYY",
  DDMMYYYYHHMMSS = "DD.MM.YYYY HH:mm:ss",
  DDMMYYYYHHMM = "DD.MM.YYYY HH:mm",
  ISOShort = "YYYY-MM-DD HH:mm",
  DayDDMonthYYYYHHMMSS = "dddd, DD. MMMM YYYY HH:mm:ss",
  DayDDMonthYYYYHHMM = "dddd, DD. MMMM YYYY HH:mm",
  HHMM = "HH:mm",
  HHMMSS = "HH:mm:ss",
  ISODate = "YYYY-MM-DD",
}

function isValidDateFormat(dateFormat: string): boolean {
  for (const key of Object.keys(DateMomentFormat)) {
    if (key === dateFormat) return true
  }
  return false
}

/**
 * Formats a date object to a formatted date string.
 *
 * @param value date to format
 * @param format to use, for possible formats see {@link DateFormat}
 * @param locale to use
 * @returns a string with the specific format or null when an error occured
 */
export function formatDate(
  value: Date | undefined | null,
  format: DateFormat,
  locale: string
): string | null {
  if (!value) return null
  const m = moment(value)
  m.locale(locale)

  if (format === "ISO") format = "ISODate"

  if (!isValidDateFormat(format)) {
    // Default
    return m.format(DateMomentFormat.DDMMYYYY)
  }

  return m.format(DateMomentFormat[format])
}
