import Time from "./Time.ce.vue"
import TimeStyles from "Dist/components/Time/Time.css?raw"
import GlobalStyles from "Dist/global.css?raw"
import { registerCustomElement } from "Utils/utils"

Time.styles = [GlobalStyles, Time.styles, TimeStyles]

export const register = () => {
  registerCustomElement("nscale-time", Time)
}
