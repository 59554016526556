import NumberField from "./NumberField.ce.vue"
import NumberFieldStyles from "Dist/components/NumberField/NumberField.css?raw"
import GlobalStyles from "Dist/global.css?raw"
import { registerCustomElement } from "Utils/utils"

NumberField.styles = [GlobalStyles, NumberField.styles, NumberFieldStyles]

export const register = () => {
  registerCustomElement("nscale-number-field", NumberField)
}
