export enum Ranges {
  yesterday = "yesterday",
  today = "today",
  tomorrow = "tomorrow",
  lastWeek = "lastWeek",
  thisWeek = "thisWeek",
  nextWeek = "nextWeek",
  lastQuarter = "lastQuarter",
  thisQuarter = "thisQuarter",
  nextQuarter = "nextQuarter",
  lastYear = "lastYear",
  thisYear = "thisYear",
  nextYear = "nextYear",
}
