import MultiValueTextField from "./MultiValueTextField.ce.vue"
import MultiValueTextFieldStyles from "../../../dist/components/MultiValueTextField/MultiValueTextField.css?raw"
import GlobalStyles from "../../../dist/global.css?raw"
import { registerCustomElement } from "Utils/utils"

MultiValueTextField.styles = [
  MultiValueTextField.styles,
  GlobalStyles,
  MultiValueTextFieldStyles,
]

export const register = () => {
  registerCustomElement("nscale-multi-value-text-field", MultiValueTextField)
}
