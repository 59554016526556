import {
  registerCalendarComponent,
  registerNumberFieldComponent,
  registerTimeComponent,
  registerMultiValueTextFieldComponent,
  registerChipComponent,
  registerSwitchComponent,
} from "./components"

registerCalendarComponent()
registerNumberFieldComponent()
registerTimeComponent()
registerMultiValueTextFieldComponent()
registerChipComponent()
registerSwitchComponent()
