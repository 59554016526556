import i18next from "i18next"
import { de, en } from "./locales"

i18next.init({
  lng: "en",
  resources: {
    de,
    en,
  },
})

export const isAllowedLanguage = (languageCode: string): boolean => {
  return languageCode === "de" || languageCode === "en"
}

export default i18next
