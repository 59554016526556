<template>
  <span :class="containerClasses">
    <input
      type="text"
      :class="'value' + (!isEditable ? ' padding' : '')"
      :value="props.value"
      @input="handleInput"
      @keydown="handleKeydown"
      :disabled="!isEditable"
    />
    <button v-show="isEditable" class="mdi-btn" @click="handleClick">
      <svg-icon
        type="mdi"
        :path="mdiClose"
        height="15"
        width="15"
        class="nscale-mdi"
      ></svg-icon>
    </button>
  </span>
</template>

<script setup lang="ts">
import { computed, ref, Ref, watch, onMounted } from "vue"
import { mdiClose } from "@mdi/js"
import SvgIcon from "@jamescoyle/vue-icon"
import { toBoolean } from "Utils/parser/boolean"

/* Why "writeprotected instead of "readonly"??? See https://vuejs.org/api/#Special-Attributes */
const props = defineProps<{
  value: string
  index: number
  writeProtected: string
  disabled: string
  isDefaultValue: string
}>()
const emit = defineEmits(["remove", "edit"])

const valueRef: Ref<string | null> = ref(null)
const writeProtectedRef: Ref<boolean> = ref(false)
const disabledRef: Ref<boolean> = ref(false)
const isDefaultValueRef: Ref<boolean> = ref(false)

watch(
  () => props.value,
  () => {
    valueRef.value = props.value
  }
)

watch(
  () => props.writeProtected,
  () => {
    writeProtectedRef.value = toBoolean(props.writeProtected)
  }
)

watch(
  () => props.disabled,
  () => {
    disabledRef.value = toBoolean(props.disabled)
  }
)

watch(
  () => props.isDefaultValue,
  () => {
    isDefaultValueRef.value = toBoolean(props.isDefaultValue)
  }
)

onMounted(() => {
  valueRef.value = props.value
  // We need to initialize all "boolean" properties
  writeProtectedRef.value = toBoolean(props.writeProtected)
  disabledRef.value = toBoolean(props.disabled)
  isDefaultValueRef.value = toBoolean(props.isDefaultValue)
})

const isEditable = computed(
  () =>
    !writeProtectedRef.value && !disabledRef.value && !isDefaultValueRef.value
)

function handleClick() {
  emit("remove", props.index)
}

function handleInput(event: Event) {
  if (event && event.target) {
    // @ts-ignore
    emit("edit", event.target.value, props.index)
  }
}

function handleKeydown(event: KeyboardEvent) {
  if (event.key === "Enter") {
    event.preventDefault()
    event.stopImmediatePropagation()
  }
}

const containerClasses = computed(() => {
  const classes: string[] = ["container"]
  if (writeProtectedRef.value) classes.push("readonly")
  if (disabledRef.value) classes.push("disabled")
  if (isDefaultValueRef.value) classes.push("default-value")
  return classes
})
</script>
