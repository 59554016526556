import Calendar from "./Calendar.ce.vue"
import VCalendarStyles from "v-calendar/dist/style.css?raw"
import GlobalStyles from "Dist/global.css?raw"
import CalendarStyles from "Dist/components/Calendar/Calendar.css?raw"
import { registerCustomElement } from "Utils/utils"

Calendar.styles = [
  Calendar.styles,
  GlobalStyles,
  VCalendarStyles,
  CalendarStyles,
]

export const register = () => {
  registerCustomElement("nscale-calendar", Calendar)
}
