import { Ranges } from "Utils/enums"
import moment from "moment"

/**
 * Calculates start and end of a range.
 *
 * @param rangeString see {@link Ranges}
 * @returns A date range {@link DateRange} or null, when the range is not available
 */
export const getRange = (
  rangeString: string,
  locale = "de"
): DateRange | null => {
  switch (rangeString) {
    case Ranges.yesterday:
      const yesterday = new Date()
      yesterday.setDate(yesterday.getDate() - 1)
      return { start: yesterday, end: yesterday }
    case Ranges.today:
      const today = new Date()
      return { start: today, end: today }
    case Ranges.tomorrow:
      const tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      return { start: tomorrow, end: tomorrow }
    case Ranges.lastWeek:
      const startLastWeek = moment()
        .locale(locale)
        .startOf("week")
        .subtract(1, "week")
      const endLastWeek = moment()
        .locale(locale)
        .endOf("week")
        .subtract(1, "week")
      return {
        start: startLastWeek.toDate(),
        end: endLastWeek.toDate(),
      }
    case Ranges.thisWeek:
      const startThisWeek = moment().locale(locale).startOf("week")
      const endThisWeek = moment().locale(locale).endOf("week")
      return {
        start: startThisWeek.toDate(),
        end: endThisWeek.toDate(),
      }
    case Ranges.nextWeek:
      const startNextWeek = moment()
        .locale(locale)
        .startOf("week")
        .add(1, "week")
      const endNextWeek = moment().locale(locale).endOf("week").add(1, "week")
      return {
        start: startNextWeek.toDate(),
        end: endNextWeek.toDate(),
      }
    case Ranges.lastQuarter:
      const startLastQuarter = moment()
        .locale(locale)
        .startOf("quarter")
        .subtract(1, "quarter")
      const endLastQuarter = moment()
        .locale(locale)
        .endOf("quarter")
        .subtract(1, "quarter")
      return {
        start: startLastQuarter.toDate(),
        end: endLastQuarter.toDate(),
      }
    case Ranges.thisQuarter:
      const startThisQuarter = moment().locale(locale).startOf("quarter")
      const endThisQuarter = moment().locale(locale).endOf("quarter")
      return {
        start: startThisQuarter.toDate(),
        end: endThisQuarter.toDate(),
      }
    case Ranges.nextQuarter:
      const startNextQuarter = moment()
        .locale(locale)
        .startOf("quarter")
        .add(1, "quarter")
      const endNextQuarter = moment()
        .locale(locale)
        .endOf("quarter")
        .add(1, "quarter")
      return {
        start: startNextQuarter.toDate(),
        end: endNextQuarter.toDate(),
      }
    case Ranges.lastYear:
      const startLastYear = moment()
        .locale(locale)
        .startOf("year")
        .subtract(1, "year")
      const endLastYear = moment()
        .locale(locale)
        .endOf("year")
        .subtract(1, "year")
      return {
        start: startLastYear.toDate(),
        end: endLastYear.toDate(),
      }
    case Ranges.thisYear:
      const startThisYear = moment().locale(locale).startOf("year")
      const endThisYear = moment().locale(locale).endOf("year")
      return {
        start: startThisYear.toDate(),
        end: endThisYear.toDate(),
      }
    case Ranges.nextYear:
      const startNextYear = moment()
        .locale(locale)
        .startOf("year")
        .add(1, "year")
      const endNextYear = moment().locale(locale).endOf("year").add(1, "year")
      return {
        start: startNextYear.toDate(),
        end: endNextYear.toDate(),
      }
  }
  return null
}

/**
 *
 * @param value
 * @returns
 */
export const toStringRange = (value: DateRange | null): StringRange => {
  if (value === null) return { start: "", end: "" }
  // moment().toISOString(true) => no UTC conversion!
  const start = moment(value.start).toISOString(true).split("T")[0]
  const end = moment(value.end).toISOString(true).split("T")[0]
  return { start, end }
}
