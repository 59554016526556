export const toNumber = (value: string): number | Error => {
    let message = "Could not parse string to number.";
    if (value.length > 0) {
      try {
        const i = parseInt(value);
        if(isNaN(i)) throw new Error(`"${value}" is not a number.`)
        return i
      } catch (e: unknown) {
        if (e instanceof Error) {
          message += " Original message: " + e.message;
        }
      }
    }
    return new Error(message);
  };