import Switch from "./Switch.ce.vue"
import SwitchStyles from "Dist/components/Switch/Switch.css?raw"
import GlobalStyles from "Dist/global.css?raw"
import { registerCustomElement } from "Utils/utils"

Switch.styles = [Switch.styles, GlobalStyles, SwitchStyles]

export const register = () => {
  registerCustomElement("nscale-switch", Switch)
}
