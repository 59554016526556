<template>
  <div :class="containerClasses" :tabindex="getTabIndex()">
    <label class="label" :for="fieldId">{{ props.label }}</label>

    <input
      :id="fieldId + '-hidden'"
      type="text"
      v-model="valueRef"
      tabindex="-1"
      class="hidden"
      aria-hidden="true"
      :readonly="writeProtectedRef"
      :disabled="disabledRef"
    />

    <div class="inner-container">
      <div ref="searchOperators">
        <slot name="operator-selection"></slot>
      </div>

      <ul class="list" :id="fieldId + '-list'">
        <li class="list-item" v-for="(v, index) in partsRef">
          <nscale-chip
            :value="v"
            :title="v"
            :index="index"
            @remove="handleRemoveChip"
            @edit="handleEdit"
            :write-protected="writeProtectedRef"
            :disabled="disabledRef || looselyDisabledRef"
            :is-default-value="showDefaultValue"
          />
        </li>
        <li v-if="!writeProtectedRef || !disabledRef">
          <input
            :id="fieldId"
            :readonly="writeProtectedRef"
            :disabled="disabledRef"
            type="text"
            v-model="partRef"
            class="text-field-add"
            @input="handleInput"
            @change="() => handleChange(true)"
            @keydown.enter="handleEnter"
            @keydown.delete="handleRemoveLast"
            :aria-describedby="fieldId + '-hidden'"
          />
        </li>
      </ul>

      <nscale-icon
        v-if="writeProtectedRef || disabledRef"
        size="medium"
        iconkey="lock"
        hover="false"
      />

      <slot name="revert-button"></slot>
      <slot name="dialog-button"></slot>
      <slot name="clear-button"></slot>
      <slot name="error-icon"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, Ref, ref, watch } from "vue"
import { generateUniqueId } from "Utils/utils"
import { toBoolean } from "Utils/parser/boolean"

const fieldId = "mv-text-field-" + generateUniqueId()
// to avoid console warnings, we MUST set every prop to string and parse it ourself
const props = defineProps<{
  value: string
  label: string
  errors: string
  writeProtected: string
  disabled: string
  hasChanges: string
  showEmptyBubble: string
  defaultValue: string
  looselyDisabled: string
}>()
const emit = defineEmits(["newChip", "input"])

const valueRef: Ref<string | null> = ref(null)
const partsRef: Ref<(string | null)[]> = ref([])
const partRef: Ref<string | null> = ref(null)
const hasErrorsRef: Ref<boolean> = ref(false)

// parsed boolean prop refs
const writeProtectedRef: Ref<boolean> = ref(false)
const hasChangesRef: Ref<boolean> = ref(false)
const showEmptyBubbleRef: Ref<boolean> = ref(false)
const disabledRef: Ref<boolean> = ref(false)
const looselyDisabledRef: Ref<boolean> = ref(false)

onMounted(() => {
  // Initially called when the component renders the first time
  valueRef.value = props.value
  partsRef.value = getValues()
  // We need to initialize all "boolean" properties
  writeProtectedRef.value = toBoolean(props.writeProtected)
  hasChangesRef.value = toBoolean(props.hasChanges)
  showEmptyBubbleRef.value = toBoolean(props.showEmptyBubble)
  disabledRef.value = toBoolean(props.disabled)
  looselyDisabledRef.value = toBoolean(props.looselyDisabled)
  hasErrorsRef.value = !!props.errors && props.errors.length > 0
})

watch(
  () => props.value,
  () => {
    // Called every time the value of props.value changes
    valueRef.value = props.value
    partsRef.value = getValues()
  }
)

watch(
  () => props.writeProtected,
  () => {
    writeProtectedRef.value = toBoolean(props.writeProtected)
  }
)

watch(
  () => props.hasChanges,
  () => {
    hasChangesRef.value = toBoolean(props.hasChanges)
  }
)

watch(
  () => props.showEmptyBubble,
  () => {
    showEmptyBubbleRef.value = toBoolean(props.showEmptyBubble)
    valueRef.value = props.value
    partsRef.value = getValues()
  }
)

watch(
  () => props.disabled,
  () => {
    disabledRef.value = toBoolean(props.disabled)
  }
)

watch(
    () => props.looselyDisabled,
    () => {
      looselyDisabledRef.value = toBoolean(props.looselyDisabled)
    }
)

watch(
  () => props.errors,
  () => {
    hasErrorsRef.value = !!props.errors && props.errors.length > 0
  }
)

watch(
  () => props.defaultValue,
  () => {
    partsRef.value = getValues()
  }
)

const containerClasses = computed((): string[] => {
  const classes: string[] = ["container"]
  if (hasErrorsRef.value) classes.push("has-errors")
  if (hasChangesRef.value) classes.push("has-changes")
  if (writeProtectedRef.value) classes.push("readonly")
  if (disabledRef.value) classes.push("disabled")
  if (looselyDisabledRef.value) classes.push("looselyDisabled")
  return classes
})

const isValueEmpty = computed((): boolean => {
  return (
    valueRef.value === null ||
    typeof valueRef.value === "undefined" ||
    (typeof valueRef.value === "string" && valueRef.value.length === 0)
  )
})

const showDefaultValue = computed(
  (): boolean => !!props.defaultValue && isValueEmpty.value
)

/* Generates an array of values by splitting the string value by separator */
function getValues(): string[] {
  if (showDefaultValue.value) {
    return multiValueStringToArray(props.defaultValue)
  }
  if (typeof valueRef.value === "string") {
    if (valueRef.value.length === 0 && !showEmptyBubbleRef.value) return []
    return multiValueStringToArray(valueRef.value)
  }
  return []
}

function multiValueStringToArray(value: string): string[] {
  const parts: string[] = []
  for (const part of value.split(";")) {
    parts.push(part)
  }
  return parts
}

const handleInput = (event: Event): void => {
  if (!(event instanceof InputEvent)) return

  // disables default input event propagation
  event.stopPropagation()

  emit("input", partRef.value)
}

function handleChange(addValue: boolean): void {
  let newValue: string | null = null

  if ((!partRef.value || !partRef.value.length) && addValue) {
    newValue = ""
  } else {
    if (partRef.value) {
      newValue = partRef.value
    }
  }

  if (newValue !== null) {
    if (showDefaultValue.value) {
      partsRef.value = [newValue]
    } else {
      partsRef.value.push(newValue)
    }
    partRef.value = null
  }
  let value = null
  for (const v of partsRef.value) {
    if (!value) {
      value = v?.trim()
    } else {
      value += ";" + v?.trim()
    }
  }
  emit("newChip", value)
}

function handleEnter(event: KeyboardEvent) {
  event.preventDefault()
  event.stopImmediatePropagation()
  event.stopPropagation()
  handleChange(true)
}

function handleRemoveLast() {
  if (!partRef.value || !partRef.value?.length) {
    // do nothing when default value is visible
    if (showDefaultValue.value) return
    partsRef.value.pop()
    handleChange(false)
  }
}

function handleRemoveChip(event: CustomEvent) {
  const index = event.detail[0]
  partsRef.value.splice(index, 1)
  handleChange(false)
}

function handleEdit(event: CustomEvent) {
  const newPartValue = event.detail[0]
  const index = event.detail[1]
  partsRef.value.splice(index, 1, newPartValue)
  handleChange(false)
}

function getTabIndex(): string {
  let tabIndex = ""
  if (writeProtectedRef.value) tabIndex = "-1"
  else if (disabledRef.value) tabIndex = "-1"
  else tabIndex = "0"
  return tabIndex
}
</script>
