<script setup lang="ts">
import { defineEmits, ref, defineProps, onMounted, watch } from "vue";
import { toNumber } from "Utils/parser/number";
import { leadingZeroesFormat } from "Utils/formatter/number";

type NumberFieldProps = {
  value: number;
  label: string;
  min: number;
  max: number;
  maxLength: number;
};

const props = defineProps<NumberFieldProps>();
const emit = defineEmits(["input"]);
const textRef = ref("00");
let numericValue = 0;
let min = 0;
let max = 100;
let maxLength = 2;

const update = (num: number) => {
  if (numericValue + num === max) {
    numericValue = min;
  } else if (numericValue + num === min - 1) {
    numericValue = max - 1;
  } else if (
    (num < min && numericValue > min) ||
    (num > min && numericValue < max)
  ) {
    numericValue += num;
  }
  textRef.value = leadingZeroesFormat(numericValue);
  emitValue();
};

const emitValue = () => {
  emit("input", numericValue);
};

const handleInput = (event: Event): void => {
  if (!(event instanceof InputEvent)) return;

  // disables default input event propagation
  event.stopPropagation();

  const newChar = event.data;
  // newChar === null is backspace
  if (newChar === null) {
    // user manually cleared field
    if (textRef.value === "") {
      numericValue = min;
      emitValue();
      return;
    }
    // user deleted char, but field is not empty
    const numericText = toNumber(textRef.value);
    if (numericText instanceof Error) return;
    numericValue = numericText;
    emitValue();
    return;
  }
  const num = toNumber(newChar);
  if (num instanceof Error || isNaN(num)) {
    // newChar is not valid
    textRef.value = textRef.value.replace(newChar, "");
    return;
  }
  let numericText = toNumber(textRef.value);
  if (numericText instanceof Error) return;
  if (numericText > max) {
    // number in field is bigger then max
    numericText = max - 1;
    textRef.value = numericText.toString();
  }
  if (textRef.value.length > maxLength) {
    textRef.value = numericText.toString();
  }
  numericValue = numericText;
  emitValue();
};

watch(
  () => props.value,
  (newProp) => {
    if (newProp.toString() !== textRef.value) {
      textRef.value = leadingZeroesFormat(newProp);
    }
  }
);

onMounted(() => {
  numericValue = props.value;
  min = props.min;
  max = props.max;
  maxLength = props.maxLength;
  textRef.value = leadingZeroesFormat(numericValue);
});
</script>

<template>
  <div class="number-field-container form-group">
    <label for="number-field" class="number-field-label">{{ label }}</label>
    <input
      name="number-field"
      @input="handleInput"
      v-model="textRef"
      class="number-field-input"
    />
    <div class="number-field-buttons-container">
      <button @click="update(+1)" class="icon-button"><nscale-icon size="SMALL" iconkey="chevron_up" hover="true"/></button>
      <button @click="update(-1)" class="icon-button"><nscale-icon size="SMALL" iconkey="chevron_down" hover="true"/></button>
    </div>
  </div>
</template>
