/**
 * Transforms the validator {@link DateValidationOptions} property from the Calendar component,
 * to a validation object which can be used by the v-calendar component.
 *
 * @param options date validation options {@link DateValidationOptions}
 * @returns Object {@link CalendarValidationOptions} with attributes,
 *          that match the properties of the v-calendar component.
 */
export const getDateValidator = (options: DateValidationOptions): CalendarValidationOptions => {
    const cvo: CalendarValidationOptions = {
        disabledDates: null,
        minDate: null,
        maxDate: null
    }

    if (Object.keys(options).length === 0) return cvo;

    // process options and extend CalendarValidationOptions object
    processAllowedWeekDaysOption(options.allowedWeekDays, cvo);
    processAllowedToday(options.allowToday, cvo);
    processLimitFutureDays(options.limitFutureDays, options.futureDays, cvo);
    processLimitPastDays(options.limitPastDays, options.pastDays, cvo);
    processMinMaxDate(options, cvo);

    return cvo;
}

const processAllowedWeekDaysOption =
    (allowedWeekDays: string | null | undefined, cvo: CalendarValidationOptions): void => {
    if(!allowedWeekDays) return;

    const disabledWeekDays: number[] = [];

    if(!allowedWeekDays.includes("Sunday")) disabledWeekDays.push(1);
    if(!allowedWeekDays.includes("Monday")) disabledWeekDays.push(2);
    if(!allowedWeekDays.includes("Tuesday")) disabledWeekDays.push(3);
    if(!allowedWeekDays.includes("Wednesday")) disabledWeekDays.push(4);
    if(!allowedWeekDays.includes("Thursday")) disabledWeekDays.push(5);
    if(!allowedWeekDays.includes("Friday")) disabledWeekDays.push(6);
    if(!allowedWeekDays.includes("Saturday")) disabledWeekDays.push(7);

    if(disabledWeekDays.length > 0) {
        if(!cvo.disabledDates) cvo.disabledDates = {};
        cvo.disabledDates.weekdays = disabledWeekDays;
    }
}

const processAllowedToday = (allowedToday: boolean | undefined, cvo: CalendarValidationOptions): void => {
    if(typeof allowedToday !== "boolean" || allowedToday) return;

    const today = new Date();

    if(!cvo.disabledDates) cvo.disabledDates = {};
    cvo.disabledDates.start = today;
    cvo.disabledDates.end = today;
}

const processLimitFutureDays = (limitFutureDays: boolean | undefined, futureDays: number | undefined, cvo: CalendarValidationOptions): void => {
    if(typeof limitFutureDays !== "boolean" || !limitFutureDays || (typeof futureDays === "number" && futureDays < 0))
        return;

    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + (typeof futureDays === "number" ? futureDays : 0));

    cvo.maxDate = maxDate;
}

const processLimitPastDays = (limitPastDays: boolean | undefined, pastDays: number | undefined, cvo: CalendarValidationOptions): void => {
    if(typeof limitPastDays !== "boolean" || !limitPastDays || (typeof pastDays === "number" && pastDays < 0)) return;

    const minDate = new Date();
    minDate.setDate(minDate.getDate() - (typeof pastDays === "number" ? pastDays : 0));

    cvo.minDate = minDate;
}

const processMinMaxDate = (options: DateValidationOptions, cvo: CalendarValidationOptions): void => {
    if(options.includeTimes) {
        if(typeof options.minDateTime === "number") {
            const minDate = new Date(options.minDateTime);
            if(cvo.minDate instanceof Date) {
                if(minDate > cvo.minDate) cvo.minDate = minDate;
            } else {
                cvo.minDate = minDate;
            }
        }

        if(typeof options.maxDateTime === "number") {
            const maxDate = new Date(options.maxDateTime);
            if(cvo.maxDate instanceof Date) {
                if(maxDate < cvo.maxDate) cvo.maxDate = maxDate;
            } else {
                cvo.maxDate = maxDate;
            }
        }
        return;
    }

    if(typeof options.minDate === "number") {
        const minDate = new Date(options.minDate);
        if(cvo.minDate instanceof Date) {
            if(minDate > cvo.minDate) cvo.minDate = minDate;
        } else {
            cvo.minDate = minDate;
        }
    }

    if(typeof options.maxDate === "number" ) {
        const maxDate = new Date(options.maxDate);
        if(cvo.maxDate instanceof Date) {
            if(maxDate < cvo.maxDate) cvo.maxDate = maxDate;
        } else {
            cvo.maxDate = maxDate;
        }
    }
}
