<script setup lang="ts">
import {
  ref,
  defineEmits,
  onBeforeMount,
  onMounted,
  onUpdated,
  computed,
  Ref,
  watch,
} from "vue"
import { Calendar } from "v-calendar"
import { getDateValidator } from "Utils/validators/date"
import { formatDate } from "Utils/formatter/date"
import i18n, { isAllowedLanguage } from "Services/i18n"
import { Ranges } from "Utils/enums"
import { getRange, toStringRange } from "Utils/parser/date"

// ------ props

// vue specific (props or emits) typings can't be moved into separate d.ts files: https://github.com/vuejs/core/issues/4294
type CalendarComponentProps = {
  value: string
  locale: string
  validator: string
  isFlexibleHeight: boolean
  selectNow: boolean
  isRange: boolean
  dateFormat: string
  showRangeList: boolean
}

const props = defineProps<CalendarComponentProps>()

// ------ emits

type CalendarComponentEvents = {
  (e: string): void
  (e: "newDate", payload: string): void
  (e: "newRange", payload: StringRange): void
  (e: "startRangeClick"): void
  (e: "endRangeClick", isFirstEndRangeClick: boolean): void
  (e: "rangeListElementClick"): void
}
const emit = defineEmits<CalendarComponentEvents>()

// ------ component members

const isStartRangeClick = ref(true)
const isFirstEndRangeClick = ref(true)
const rangeValue: Ref<DateRange | null> = ref(null)
const value = ref("")
const calendar: Ref<VCalendarReference | null> = ref(null)
const container: Ref<HTMLDivElement | null> = ref(null)

// ------ computed

const dateValidator = computed((): CalendarValidationOptions => {
  // web component attributes are always strings: https://github.com/vuejs/vue-web-component-wrapper#props
  try {
    const validator = JSON.parse(props.validator)
    return getDateValidator(validator)
  } catch (e: unknown) {
    if (e instanceof Error) {
      console.error(
        `Calendar: Could not parse attribute 'validator'. Original message: ${e.message}`
      )
    }
  }
  return { minDate: null, maxDate: null, disabledDates: null }
})

const calendarClasses = computed((): string[] => {
  const classes: string[] = []
  if (props.isFlexibleHeight) classes.push("nscale-calendar-flexible-height")
  return classes
})

const startButtonClasses = computed((): string[] => {
  const classes = ["range-button"]
  if (isStartRangeClick.value) classes.push("is-active")
  return classes
})

const endButtonClasses = computed((): string[] => {
  const classes = ["range-button"]
  if (!isStartRangeClick.value) classes.push("is-active")
  if (rangeValue.value === null) classes.push("is-disabled")
  return classes
})

const attributes = computed((): VCalendarAttributes => {
  const attrs: VCalendarAttributes = []

  // mark today
  attrs.push({
    key: "today",
    dot: true,
    color: "primary-2",
    dates: new Date(),
  })

  // when !props.isRange only highlight.start is visible
  attrs.push({
    highlight: {
      start: {
        color: "blue",
        fillMode: "solid",
      },
      base: {
        color: "blue",
        fillMode: "light",
      },
      end: {
        color: "blue",
        fillMode: "solid",
      },
    },
    dates: selectedDateFromPropsValue(),
  })

  return attrs
})

// ------ event handler

const handleDate = (event: VCalendarEvent): void => {
  if (
    event &&
    (event.event instanceof MouseEvent ||
      (event.event instanceof KeyboardEvent && event.event.key === "Enter"))
  ) {
    if (event.event instanceof KeyboardEvent && event.event.key === "Enter")
      event.event.preventDefault()
    if (!event.isDisabled) {
      if (props.isRange) {
        if (isStartRangeClick.value) {
          const startDate = new Date(event.id)
          if (rangeValue.value !== null && startDate.getTime() < rangeValue.value.end.getTime()) {
            // start and end exist, start < end
            rangeValue.value.start = startDate;
          } else {
            // start and end do not exist or start > end
            rangeValue.value = {
              start: startDate,
              end: startDate,
            }
          }
          isStartRangeClick.value = false
          // end range is automatically focused, as if it was clicked
          emit("endRangeClick", isFirstEndRangeClick.value);
        } else if (rangeValue.value && !isStartRangeClick.value) {
          const endDate = new Date(event.id)
          if (endDate.getTime() <= rangeValue.value.start.getTime()) {
            // start = end, when end less than start
            rangeValue.value = {
              start: new Date(event.id),
              end: new Date(event.id),
            }
          } else {
            // end > start
            rangeValue.value.end = new Date(event.id)
          }
          isFirstEndRangeClick.value = false;
        }
        if(rangeValue.value !== null)
          emit("newRange", toStringRange(rangeValue.value))
      } else {
        emit("newDate", event.id)
      }
    }
  }
}

const handleRangeListElementClick = (key: string): void => {
  const range = getRange(key)
  if (range) {
    rangeValue.value = range
    isFirstEndRangeClick.value = false;
    move(range.end)
    emit("rangeListElementClick");
    emit("newRange", toStringRange(rangeValue.value))
  }
}

const handleStartRangeButtonClick = (): void => {
  isStartRangeClick.value = true
  if (rangeValue.value) {
    move(rangeValue.value.start);
  }
  emit("startRangeClick");
}

const handleEndRangeButtonClick = (): void => {
  isStartRangeClick.value = false
  if (rangeValue.value) {
    move(rangeValue.value.end);
  }
  emit("endRangeClick", isFirstEndRangeClick.value);
}

// ------ watcher

watch(
  () => props.value,
  () => {
    updateRange();
    moveToEndDate();
  }
)

// ------ life cycle hooks

onBeforeMount(() => {
  if (isAllowedLanguage(props.locale)) {
    i18n.changeLanguage(props.locale)
  }
})

onMounted(() => {
  focusSelectedDate();
  updateRange();
  moveToEndDate();
  exposeComponentMethods();
})

onUpdated(() => {
  focusSelectedDate();
})

// ------ utilities

const selectedDateFromPropsValue = (): Date | DateRange | null => {
  if (props.isRange) {
    return rangeValue.value
  }

  if (!props.value) {
    if (props.selectNow) {
      const today = new Date()
      const dateAndTime = today.toISOString().split("T")
      emit("newDate", dateAndTime[0])
      return today
    }
    return null
  }

  return new Date(props.value)
}

const focusSelectedDate = (): void => {
  if (props.isRange) return
  setTimeout(() => {
    let date = new Date()
    if (props.value) {
      date = new Date(props.value)
    }
    if (calendar.value) calendar.value.focusDate(date)
  }, 0)
}

const dateToStringOrPlaceholder = (
  date: Date | undefined,
  placeholder: string,
  isEndClick = false
): string => {
  if (isEndClick && isFirstEndRangeClick.value) {
    return placeholder;
  }
  const formattedDate = formatDate(
    date,
    props.dateFormat as DateFormat,
    props.locale
  )
  if (formattedDate === null) return placeholder
  if (formattedDate === "Invalid date") return i18n.t("invalidDate");
  return formattedDate
}

const updateRange = (): void => {
  if (props.isRange) {
    if (props.value.includes(";")) {
      const dataParts = props.value.split(";");
      const start = new Date(dataParts[0]);
      const end = new Date(dataParts[1]);
      if (isNaN(start.getTime()) || isNaN(end.getTime())) {
        // if one of the dates is invalid, picker will be reseted
        resetRangePicker();
        return;
      }
      rangeValue.value = { start, end };
      isStartRangeClick.value = false;
    } else if (props.value.length === 0) {
      resetRangePicker();
    }
  }
}

const resetRangePicker = (): void => {
  rangeValue.value = null
  isStartRangeClick.value = true
  isFirstEndRangeClick.value = true
}

const moveToEndDate = (): void => {
  if (props.isRange) {
    if (rangeValue.value !== null) {
      move(rangeValue.value.end);
      isStartRangeClick.value = false;
    }
    if (rangeValue.value === null) {
      move(new Date());
      isStartRangeClick.value = true;
    }
  }
}

const move = async (date: Date): Promise<void> => {
  if (!calendar.value) return;
  try {
    await calendar.value.move(date);
  } catch (e: unknown) {
    if (e instanceof Error) {
      const message = "Could not move to end date. Original message:"
      if (isNaN(date.getTime())) {
        console.error(`${message} invalid date.`);
      } else {
        console.error(`${message} ${e.message}`);
      }
    }
  }
}

/**
 * Writes component methods to the shadow root host
 */
const exposeComponentMethods = (): void => {
  if (!container.value) return;
  const hostNode: CalendarHostReference | undefined = ((container.value.getRootNode() as ShadowRoot).host as CalendarHostReference);
  if (typeof hostNode === "undefined") return;

  // define public component methods
  hostNode.moveToEndDate = moveToEndDate
}
</script>

<template>
  <div class="calendar-container-outer" ref="container">
    <div
      v-if="props.isRange && props.showRangeList"
      class="range-list-container"
    >
      <div
        v-for="(k, i) in Object.keys(Ranges)"
        :key="i"
        class="range-list-element"
      >
        <button
          class="range-list-element-button"
          @click="handleRangeListElementClick(k)"
          >{{ i18n.t(k) }}</button
        >
        <hr
          v-if="(i + 1) % 3 === 0 && i < Object.keys(Ranges).length - 1"
          class="range-list-divider"
        />
      </div>
    </div>
    <div class="calendar-container-inner">
      <div v-if="props.isRange" class="range-button-container">
        <button
          :class="startButtonClasses"
          @click="handleStartRangeButtonClick"
        >
          {{
            dateToStringOrPlaceholder(rangeValue?.start, i18n.t("range.start"))
          }}
        </button>
        <button :class="endButtonClasses" @click="handleEndRangeButtonClick" :disabled="rangeValue === null">
          {{ dateToStringOrPlaceholder(rangeValue?.end, i18n.t("range.end"), true) }}
        </button>
      </div>
      <Calendar
        :class="calendarClasses" is-range
        ref="calendar"
        show-iso-weeknumbers="left"
        :value="value"
        :locale="{
          isRange,
          id: locale,
          firstDayOfWeek: 2,
          masks: {
            weekdays: 'WW',
          },
        }"
        :attributes="attributes"
        :min-date="dateValidator.minDate"
        :max-date="dateValidator.maxDate"
        :disabled-dates="dateValidator.disabledDates"
        @dayclick="handleDate"
        @daykeydown="handleDate"
      />
    </div>
  </div>
</template>
