import Chip from "./Chip.ce.vue"
import ChipStyles from "../../../dist/components/Chip/Chip.css?raw"
import GlobalStyles from "../../../dist/global.css?raw"
import { registerCustomElement } from "Utils/utils"

Chip.styles = [Chip.styles, GlobalStyles, ChipStyles]

export const register = () => {
  registerCustomElement("nscale-chip", Chip)
}
