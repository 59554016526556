<script setup lang="ts">
import {
  defineEmits,
  defineProps,
  onBeforeMount,
  onMounted,
  onUpdated,
  ref,
} from "vue"
import i18n, { isAllowedLanguage } from "Services/i18n"
import { toBoolean } from "Utils/parser/boolean"

type TimeComponentProps = {
  hours: number
  minutes: number
  seconds: number
  locale: string
  hideSeconds: boolean
}

const emit = defineEmits(["hour", "minute", "second"])
const props = defineProps<TimeComponentProps>()

const hoursRef = ref(0)
const minutesRef = ref(0)
const secondsRef = ref(0)
const hideSecondsRef = ref(false)

const handler = (e: CustomEvent, eventName: "hour" | "minute" | "second") => {
  if (typeof e.detail[0] === "number") emit(eventName, e.detail[0])
}

const updateRefs = (): void => {
  hoursRef.value = props.hours
  minutesRef.value = props.minutes
  secondsRef.value = props.seconds
  hideSecondsRef.value = toBoolean(props.hideSeconds)
}

onBeforeMount(() => {
  if (isAllowedLanguage(props.locale)) {
    i18n.changeLanguage(props.locale)
  }
})

onMounted(() => updateRefs())
onUpdated(() => updateRefs())
</script>

<template>
  <div class="time-component">
    <nscale-number-field
      class="number-field"
      :label="i18n.t('hour')"
      :value="hoursRef"
      :min="0"
      :max="24"
      :max-length="2"
      @input="(e: CustomEvent) => handler(e, 'hour')"
    />
    <span class="time-picker-colon">:</span>
    <nscale-number-field
      class="number-field"
      :label="i18n.t('minute')"
      :value="minutesRef"
      :min="0"
      :max="60"
      :max-length="2"
      @input="(e: CustomEvent) => handler(e, 'minute')"
    />
    <span v-if="!hideSeconds" class="time-picker-colon">:</span>
    <nscale-number-field
      v-if="!hideSeconds"
      class="number-field"
      :label="i18n.t('second')"
      :value="secondsRef"
      :min="0"
      :max="60"
      :max-length="2"
      @input="(e: CustomEvent) => handler(e, 'second')"
    />
    <span class="time-picker-hour">{{ i18n.t("clock") }}</span>
  </div>
</template>

<style scoped></style>
