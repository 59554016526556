import { ResourceLanguage } from "i18next"

export default {
  translation: {
    clock: "Uhr",
    hour: "Stunde",
    minute: "Minute",
    second: "Sekunde",
    range: {
      start: "Startdatum",
      end: "Enddatum",
    },
    yesterday: "Gestern",
    today: "Heute",
    tomorrow: "Morgen",
    lastWeek: "Letzte Woche",
    thisWeek: "Diese Woche",
    nextWeek: "Nächste Woche",
    lastQuarter: "Letztes Quartal",
    thisQuarter: "Dieses Quartal",
    nextQuarter: "Nächstes Quartal",
    lastYear: "Letztes Jahr",
    thisYear: "Dieses Jahr",
    nextYear: "Nächstes Jahr",
    invalidDate: "Invalides Datum"
  },
} as ResourceLanguage
